<template>
  <v-container>
    <v-subheader class="mb-3">
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">{{ platform.name }} Games</strong>
      <v-spacer />
      <div>
        <v-select
          v-model="orderby"
          solo
          flat
          background-color="box_bg"
          dense
          :items="orderbyitems"
          :label="$t('paixu')"
          no-data-text=""
          hide-details
          style="width: 6rem;"
        />
      </div>
    </v-subheader>
    <HomeP
      :platform="platform_id"
      :orderby="orderby"
    />
    <BackTop />
  </v-container>
</template>

<script>
import BackTop from '@/components/BackTop.vue'
import HomeP from '@/views/pages/Home_p.vue'
export default {
  components: {
    BackTop,
    HomeP
  },
  data: () => ({
    orderbyitems: [
      {'text': 'Default', 'value': ''},
      {'text': 'A-Z', 'value': 'az'},
      {'text': 'Z-A', 'value': 'za'},
      {'text': 'New', 'value': 'new'}
    ],
    orderby: '',
    platform: {},
    platform_id: 0
  }),
  watch:{
    orderby: {
      handler(newVal, oldVal) {
        
      }
    }
  },
  created() {
    this.platform_id = parseInt(this.$route.params.id)
    let paramObj = {
      platform_id:this.platform_id
    }
    console.log(this.$route.params)
    this.$server.games_platform(paramObj).then((response) => {
      if(response.code==200){
        this.platform = response.data
      }else{
        this.$snackbar.error(response.msg)
      }
    })
  }
}
</script>